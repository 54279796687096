<template>
  <div class="container">
    <van-nav-bar title="预约安装登记" />
    <van-form class="form" ref="form" @submit="onSubmit">
      <van-field
        v-model="bookingInstallContacts"
        name="联系人"
        label="联系人"
        :border="false"
        placeholder="名字"
        :rules="[{ required: true, message: '请填写联系人' }]"
      />
      <van-field
        v-model="bookingPhoneNo"
        type="digit"
        name="手机号码"
        label="手机号码"
        placeholder="请填写手机号码"
        :border="false"
        :rules="[
          { required: true, message: '请填写手机号码' },
          { pattern: phoneReg, message: '请输入正确手机号' }
        ]"
      />
      <van-field
        v-model="fieldValue"
        :right-icon="cascader_icon"
        readonly
        :border="false"
        label="地区"
        placeholder="省、市、区"
      />
      <van-popup v-model="show" round position="bottom">
        <van-cascader
          v-model="cascaderValue"
          :options="options"
          @close="show = false"
          @change="onChange"
          @finish="onFinish"
        />
      </van-popup>

      <van-field
        v-model="bookingDetailedAddress"
        name="详细地址"
        label="详细地址"
        :border="false"
        placeholder="街道、楼牌号"
        :rules="[{ required: true, message: '请填写详细地址' }]"
      />
    </van-form>
    <div class="footer">
      <div class="desc">
        <van-icon name="info-o" color="#CB7E0E" />
        预约功能目前仅支持杭州拱墅区朗诗寓瓜山东苑
      </div>
      <van-button
        style="width: 315px; margin: auto"
        color="#CB7E0E"
        round
        block
        type="info"
        :loading="loading"
        loading-text="提交"
        @click="handleSubmit"
        >提交</van-button
      >
      <div class="info">
        完成信息登记后，我们会安排专人为您服务，请保持电话畅通
      </div>
    </div>
  </div>
</template>

<script>
import { province, city, district, installApply } from '@/api/user.js'
import { phoneReg } from '@/utils/reg.js'
export default {
  data() {
    return {
      show: false,
      fieldValue: '浙江省/杭州市/拱墅区',
      bookingInstallContacts: '',
      bookingPhoneNo: '',
      bookingDetailedAddress: '郎诗寓瓜山东苑',
      cascaderValue: 330105,
      cascaderCode: [33, 3301, 330105],
      options: [],
      cascader_icon: require('../../assets/icon/cascader_icon.png'),
      phoneReg,
      loading: false
    }
  },
  created() {
    this.getProvince()
  },
  methods: {
    onSubmit() {
      this.loading = true
      const params = {
        bookingInstallContacts: this.bookingInstallContacts,
        bookingPhoneNo: this.bookingPhoneNo,
        provinceId: this.cascaderCode[0],
        cityId: this.cascaderCode[1],
        districtId: this.cascaderCode[2],
        bookingDetailedAddress: this.bookingDetailedAddress
      }
      installApply(params)
        .then((res) => {
          this.loading = false
          if (res.status === 200) {
            this.$toast.success('提交成功')
            this.bookingInstallContacts = ''
            this.bookingPhoneNo = ''
            setTimeout(() => {
              this.$router.replace({ name: 'installSuccess' })
            }, 1000)
          }
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleSubmit() {
      this.$refs.form.submit()
    },
    onFinish({ selectedOptions }) {
      this.show = false
      console.log(selectedOptions.map((option) => option.text).join('/'))
      this.fieldValue = selectedOptions.map((option) => option.text).join('/')
      console.log(selectedOptions.map((option) => option.value))
    },
    getProvince() {
      province().then((res) => {
        if (res.status === 200) {
          this.options = res.data.map((item) => {
            const obj = {}
            obj.text = item.provinceName
            obj.value = item.provinceId
            obj.children = []
            return obj
          })
        }
      })
    },
    onChange(val) {
      console.log(val)
      if (val.tabIndex === 0) {
        // 获取市
        this.getCity(val.value)
      } else if (val.tabIndex === 1) {
        // 获取区
        this.getDistrict(val.selectedOptions[0].value, val.value)
      }
    },
    getCity(provinceId) {
      let cityList = []
      city(provinceId).then((res) => {
        if (res.status === 200) {
          cityList = res.data.map((item) => {
            const obj = {}
            obj.text = item.cityName
            obj.value = item.cityId
            obj.children = []
            return obj
          })
          const index = this.options.findIndex(
            (province) => province.value === provinceId
          )
          this.options[index].children = cityList
        }
      })
    },
    getDistrict(provinceId, cityId) {
      let districtList = []
      district(cityId).then((res) => {
        if (res.status === 200) {
          districtList = res.data.map((item) => {
            const obj = {}
            obj.text = item.districtName
            obj.value = item.districtId
            return obj
          })
          let firstIndex = this.options.findIndex(
            (item) => item.value === provinceId
          )
          let cities = this.options[firstIndex].children
          let index = cities.findIndex((item) => item.value === cityId)
          cities[index].children = districtList
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.form {
  padding-top: 10px;
  /deep/.van-field__label {
    color: #ffffff;
  }
  /deep/.van-cell {
    margin-bottom: 17px;
  }
  /deep/.van-cell--clickable:active {
    background-color: transparent;
  }
}
.footer {
  position: absolute;
  width: 100%;
  bottom: 30px;
  text-align: center;
  .desc {
    font-size: 12px;
    color: #ffffff;
    margin-bottom: 7px;
  }
  .info {
    font-size: 12px;
    color: #aaaaaa;
    margin-top: 10px;
  }
}
/deep/.van-cascader__options {
  color: #777777;
}
/deep/.van-cascader__option:active {
  background-color: transparent;
}
</style>
